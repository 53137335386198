<template>
	<div class="p-login">
    <div class="body">
      <img class="banner" src="/image/login/banner.png"/>
      <div class="form">
        <!-- <div class="title">机构版登录入口</div> -->
        <div class="username">
          <i class="ss-passport icon-username"/>
          <input placeholder="账号" v-model.trim="loginName" @keydown.enter="login(form)"/>
        </div>
        <div class="password">
          <i class="ss-passport icon-password"/>
          <input type="password" placeholder="密码" v-model.trim="form.loginPasswd" @keydown.enter="login(form)"/>
        </div>
        <!-- <div class="other">
          <router-link to="/reset">忘记密码</router-link>
          <a :href="$env.register">免费注册</a>
        </div> -->
        <div class="option">
          <button @click="login(form)">登<span></span>录</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        loginName: "",
        loginPasswd: "",
      },
    };
  },
  computed: {
    loginName: {
      get() {
        return this.form.loginName;
      },
      set(value) {
        this.form.loginName = value.replace(/：/gm, ":");
      },
    },
  },
  methods: {
    async login(form) {
      if (!form.loginName) {
        this.$message.warn("请填写账户");
      } else if (!form.loginPasswd) {
        this.$message.warn("请填写密码");
      } else {
        const { domain, token } = await this.$apis["/登录"](form);
        location.replace(
          `${
            {
              dev: "//dev.5xingfu.com:7002",
            }[this.$env.from] ||
            this.$route.query.forward ||
            location.hostname.replace(/^\w*/gm, "//os")
          }#${token}`
        );
      }
    },
  },
};
</script>

<style lang="less">
.p-login {
  height: 608px;
  min-width: 1100px;
  background-size: cover;
  background-position: 50%;
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-image: url("/image/login/bg.jpg");
  > .body {
    width: 1100px;
    display: flex;
    margin: 61px auto;
    align-items: center;
    > .banner {
      margin-right: 48px;
    }
    > .form {
      width: 428px;
      height: 424px;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      padding: 9px 19px 17px 9px;
      background-repeat: no-repeat;
      background-image: url("/image/login/form.png");
      > .title {
        margin: 50px;
        font-size: 24px;
        text-align: center;
      }
      > .username,
      > .password {
        display: flex;
        margin: 20px 50px;
        line-height: 30px;
        align-items: center;
        border-style: solid;
        border-color: #cccccc;
        border-width: 0px 0px 1px 0px;
        > i {
          margin: 5px;
          font-size: 18px;
          color: #666666;
          line-height: initial;
        }
        > input {
          flex: 1;
          border: none;
          outline: none;
          font-size: 16px;
          // color: #333333;
          padding: 0px 4px;
          line-height: inherit;
          background-color: transparent;
          &::placeholder {
            font-size: 14px;
            color: #999999;
          }
          &:-webkit-autofill {
            font-size: 16px !important;
            background-color: transparent !important;
            // -webkit-text-fill-color: #333333;
            transition: background-color 31536000s cubic-bezier(1, 0, 1, 0);
          }
          &[type="password"] {
            letter-spacing: 4px;
            &::placeholder {
              letter-spacing: initial;
            }
          }
        }
      }
      > .other {
        margin: 20px 50px;
        text-align: right;
        > a {
          &:not(:hover) {
            color: #666666;
          }
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
      > .option {
        margin: 40px 50px;
        > button {
          width: 100%;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 16px;
          color: #ffffff;
          line-height: 34px;
          border-radius: 4px;
          background-color: #41ac76;
          > span {
            margin: 0px 10px;
          }
        }
      }
    }
  }
}
</style>